
import { call, put, takeLatest } from 'redux-saga/effects'
import { saveAs } from 'file-saver'

import * as ACTIONS from './actions'
import * as CONSTANTS from './constants'

import { ActionstepDocument } from 'utils/wcaApiTypes'
import { SpecialMeterReadingViewModel, SpecialMeterReadingMatter } from '../utils/specialMeterReadingWCAApiTypes'
import {
    GeneratePDF, SavePDF, GetSpecialMeterReadingMatter,
    SaveSpecialMeterReadingMatter, DeleteSpecialMeterReadingMatter
} from './actionTypes'
import { rawPost, postRequest, getRequest, deleteRequest } from 'utils/request'
import axios from 'utils/axios'

// by default, axios would call the json stringify function that would convert any date into utc date
// however, this will cause issues with the pdf generation where datetime is [x date]-00:00, which when converted into utc would be the previous day
// therefore the date transformer is needed to force preserve that locale date for correct pdf date generation
// this is the copy from the saga.ts file in settlement calc folder
function dateTransformer(data: any): any {
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toLocaleString("en-US");
    }
    if (Array.isArray(data)) {
        return data.map(val => dateTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) =>
            [key, dateTransformer(val)]))
    }
    return data
}

async function postAndSaveFile(url: string, params: any) {
    let response = await rawPost(url, params, { responseType: 'blob', transformRequest: [dateTransformer].concat(axios.defaults.transformRequest ?? []) });

    // Log somewhat to show that the browser actually exposes the custom HTTP header
    const currentDate = new Date();
    const dateFormat = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear() + "-" + Date.now();
    const fileName = params.matterId + "_" + params.settlementData.additionalInfo?.["statementType"] ?? "None" + "_" + dateFormat + ".pdf";

    // Let the user save the file.
    saveAs(response.data, fileName);
}

export function* generatePDF(action: GeneratePDF) {
    try {
        yield call(postAndSaveFile, "/api/specialMeterReading-calculator/generate-pdf", action.params);

        yield put(ACTIONS.generatePDFSuccess('success'));
    } catch (error) {
        yield put(ACTIONS.generatePDFFailed(error?.response?.data));
    }
}

export function* savePDF(action: SavePDF) {
    try {
        const response = yield call(postRequest, "/api/specialMeterReading-calculator/save-pdf", action.params);

        const data: ActionstepDocument = response as ActionstepDocument;

        yield put(ACTIONS.savePDFSuccess(data));
    } catch (error) {
        yield put(ACTIONS.savePDFFailed(error?.response?.data));
    }
}

export function* getSpecialMeterReadingMatter(action: GetSpecialMeterReadingMatter) {
    try {
        const response = yield call(getRequest, "/api/specialMeterReading-calculator/specialMeterReading-matter/" + action.params.orgKey + "/" + action.params.matterId);
        const data: SpecialMeterReadingViewModel = response as SpecialMeterReadingViewModel;
        yield put(ACTIONS.getSpecialMeterReadingMatterSuccess(data));
    } catch (error) {
        yield put(ACTIONS.getSpecialMeterReadingMatterFailed(error?.response?.data));
    }
}

export function* saveSpecialMeterReadingMatter(action: SaveSpecialMeterReadingMatter) {
    try {
        const response = yield call(postRequest, "/api/specialMeterReading-calculator/specialMeterReading-matter/", action.params);
        const data: SpecialMeterReadingMatter = response as SpecialMeterReadingMatter;

        yield put(ACTIONS.saveSpecialMeterReadingMatterSuccess(data));
    } catch (error) {
        yield put(ACTIONS.saveSpecialMeterReadingMatterFailed(error?.response?.data));
    }
}

export function* deleteSpecialMeterReadingMatter(action: DeleteSpecialMeterReadingMatter) {
    try {
        const { data } = yield call(deleteRequest, "/api/specialMeterReading-calculator/specialMeterReading-matter/" + action.params.orgKey + "/" + action.params.matterId);

        yield put(ACTIONS.deleteSpecialMeterReadingMatterSuccess(data));
    } catch (error) {
        yield put(ACTIONS.deleteSpecialMeterReadingMatterFailed(error?.response?.data));
    }
}

export default function* specialMeterReadingInfoSaga() {
    yield takeLatest(CONSTANTS.GENERATE_PDF_REQUESTED, generatePDF);
    yield takeLatest(CONSTANTS.SAVE_PDF_REQUESTED, savePDF);
    yield takeLatest(CONSTANTS.GET_SPECIAL_METER_READING_REQUESTED, getSpecialMeterReadingMatter);
    yield takeLatest(CONSTANTS.SAVE_SPECIAL_METER_READING_REQUESTED, saveSpecialMeterReadingMatter);
    yield takeLatest(CONSTANTS.DELETE_SPECIAL_METER_READING_REQUESTED, deleteSpecialMeterReadingMatter);
}