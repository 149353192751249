import * as React from 'react';
import { TextField, ChoiceGroup, Separator } from 'office-ui-fabric-react/lib';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;

type AppStates = {}

export default class OtherAdjustment extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Adjustment Type : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                data-cy="adjustment_type_input"
                                defaultValue={updatedState['adjustment_type']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'adjustment_type')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm2">Description : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                data-cy="description_input"
                                defaultValue={updatedState['description']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'description')}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Amount : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                type="number"
                                data-cy="amount_input"
                                defaultValue={updatedState['amount']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'amount')}
                            />
                        </div>
                        
                        <div className="ms-Grid-col ms-sm6" />
                    </div>

                    <Separator>
                        <b>Status</b>
                    </Separator>

                    <div className="ms-Grid-row modal-row">

                        <div>
                            <ChoiceGroup
                                className="defaultChoiceGroup"
                                defaultSelectedKey={updatedState['status']}
                                options={[
                                    {
                                        key: 'plus',
                                        text: 'Plus',
                                    },
                                    {
                                        key: 'less',
                                        text: 'Less'
                                    },
                                ]}
                                required={true}
                                onChange={(ev, item) => this.props.updateValue(item!.key, 'status', true)}
                            />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}