import * as React from 'react';
import { TextField, ChoiceGroup, Separator } from 'office-ui-fabric-react/lib';
import CustomDatePickers from 'components/common/customDatePickers';
import { _onFormatDateDDMMYYYY } from 'utils/dataFormatter'
import { Dropdown } from '@fluentui/react';
import { getAdjustmentPeriods, billingPeriodOptions, getCTSOptions } from '../Helper';
import { apportionmentOption } from '../common';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    matterDetails: any;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;

type AppStates = {}

export default class OtherAdjustmentDate extends React.Component<AppProps, AppStates> {

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;
        const matterDetails = this.props.matterDetails;

        const onPeriodChange = (selectedPeriod: any, whichValue: string) => {
            const datePeriod: any = getAdjustmentPeriods(selectedPeriod, matterDetails.settlementDate);
            this.props.updateValue(selectedPeriod, whichValue);

            if (selectedPeriod === 'Daily')
                this.props.updateValue(null, 'from');
            else
                this.props.updateValue(datePeriod.fromDate, 'from');

            this.props.updateValue(datePeriod.toDate, 'to');

            this.setState({
                refresh: true
            });
        }

        const ctsOptions = getCTSOptions();

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Adjustment Type: </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                data-cy="adjustment_type_input"
                                defaultValue={updatedState['adjustment_type']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'adjustment_type')}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm2">Description: </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                data-cy="description_input"
                                defaultValue={updatedState['description']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'description')}
                            />
                        </div>

                    </div>

                    <div className="ms-Grid-row modal-row">
                        <div className="ms-Grid-col ms-sm2">Amount: </div>
                        <div className="ms-Grid-col ms-sm4">
                            <TextField
                                type="number"
                                data-cy="amount_input"
                                defaultValue={updatedState['amount']}
                                onChange={(ev, newText) => this.props.updateValue(newText, 'amount')}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6"></div>
                    </div>

                    <div className="ms-Grid-row  modal-row">
                        <div className="ms-Grid-col ms-sm2">Billing Period: </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                placeholder="Please Select"
                                data-cy="billingPeriod_select"
                                label=''
                                options={billingPeriodOptions()}
                                defaultSelectedKey={updatedState['billingPeriod']}
                                onChange={(ev, newItem) => this.props.updateValue(newItem?.key, 'billingPeriod')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm2">From: </div>

                        <div className="ms-Grid-col ms-sm4">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="from_date_input"
                                datePickerName="from"
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row  modal-row">
                        <div className="ms-Grid-col ms-sm2">Adjustment Period : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                placeHolder='Please Select'
                                data-cy="adjustmentPeriod_select"
                                label=''
                                options={billingPeriodOptions()}
                                defaultSelectedKey={updatedState['adjustmentPeriod']}
                                onChange={(ev, newItem) => onPeriodChange(newItem?.key, 'adjustmentPeriod')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm2">To: </div>

                        <div className="ms-Grid-col ms-sm4">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="to_date_input"
                                datePickerName="to"
                            />
                        </div>
                    </div>

                    <Separator>
                        <b>Status</b>
                    </Separator>

                    <div className="ms-Grid-row modal-row">

                        <div>
                            <ChoiceGroup
                                className="defaultChoiceGroup"
                                defaultSelectedKey={updatedState['status']}
                                options={[
                                    {
                                        key: 'paid',
                                        text: 'Paid',
                                    },
                                    {
                                        key: 'unpaid',
                                        text: 'Unpaid'
                                    }
                                ]}
                                required={true}
                                onChange={(ev, item) => this.props.updateValue(item!.key, 'status', true)}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-col ms-sm12">
                        <Separator>
                            <b>Apportionment Option</b>
                        </Separator>

                        <div className="ms-Grid-row modal-row">

                            <div className="ms-Grid-col ms-sm2">CTS Option:</div>
                            <div className="ms-Grid-col ms-sm4">
                                <Dropdown
                                    data-cy="cts_option_select"
                                    id="cts_option_select"
                                    options={ctsOptions}
                                    defaultSelectedKey={updatedState['ctsOption']}
                                    onChange={(ev, item) => this.props.updateValue(item!.key, 'ctsOption', true)}
                                />
                            </div>

                            {updatedState['ctsOption'] === apportionmentOption.sharedPercentage &&
                                <div className="ms-Grid-col ms-sm6">
                                    <div className="ms-Grid-col ms-sm4">Percentage(%):</div>
                                    <div className="ms-Grid-col ms-sm8">
                                        <TextField
                                            type="number"
                                            data-cy="percentage_input"
                                            defaultValue={updatedState['percentage']}
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'percentage')}
                                        />
                                    </div>
                                </div>
                            }

                            {updatedState['ctsOption'] === apportionmentOption.entitlement &&
                                <div className="ms-Grid-col ms-sm6">
                                    <div className="ms-Grid-col ms-sm4">Value:</div>
                                    <div className="ms-Grid-col ms-sm8">
                                        <TextField
                                            data-cy="entitlement_input"
                                            placeholder='Lot Allocation/Aggregate'
                                            defaultValue={updatedState['entitlementValue']}
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'entitlementValue')}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}