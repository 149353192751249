import * as React from 'react';
import { TextField, ChoiceGroup, Separator } from 'office-ui-fabric-react/lib';
import { Dropdown, Modal } from '@fluentui/react';
import { ModalIDs, apportionmentOption } from '../common';
import { getAdjustmentPeriods, billingPeriodOptions, getCTSOptions, showAdditionalFields } from '../Helper';
import CommonField from 'components/common/commonField';
import CustomDatePickers from 'components/common/customDatePickers';
import { ActionstepMatter, MatterDetails } from 'utils/wcaApiTypes';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import "../settlementCalculator.css";
import Modals from '.';

interface IMapStateToProps { }
interface IMapDispatchToProps { }

interface IProps {
    updatedState: any;
    updateValue: (newValue: any, whichValue: string, needRefresh?: boolean) => void;
    modalName: string;
    matterDetails: MatterDetails;
    onCreateChequeSave: (modalID: ModalIDs | null, index: number | null, data: any) => void;
}

type AppProps = IMapStateToProps & IProps & IMapDispatchToProps;
type AppStates = {
    status: string | undefined,
    createCheque: boolean,
    showChequeModal: boolean,
    refresh: boolean,
    modalData: any
}

export default class AdjustmentTemplate extends React.Component<AppProps, AppStates> {

    constructor(props: Readonly<AppProps>) {
        super(props);
        this.state = {
            status: "",
            createCheque: false,
            showChequeModal: false,
            refresh: false,
            modalData: {}
        }

        this.onCreateCheque = this.onCreateCheque.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onStatusUpdate = this.onStatusUpdate.bind(this);
    }

    onCreateCheque(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            createCheque: e.target.checked,
            // set to true if checkbox is checked
            showChequeModal: this.state.showChequeModal || e.target.checked
        });
    }

    onUpdate(newValue: any, whichValue: string, needRefresh: boolean | undefined) {
        let modalData = this.state.modalData;
        modalData[whichValue] = newValue;
        this.setState({
            modalData: modalData
        });
    }

    onSave() {
        let data = {
            description: this.state.modalData.description ?? "",
            amount: this.state.modalData.amount ?? "0.0",
            rowNumber: this.state.modalData ?? 0
        }
        this.props.onCreateChequeSave(ModalIDs.payeeDetails, -1, data);
        this.setState({
            showChequeModal: false
        })
    }

    onDelete() {
        // unused at the moment
    }

    onStatusUpdate(item: any) {
        this.setState({ status: item!.key })
        this.props.updateValue(item!.key, 'status', false);
    }

    public render(): JSX.Element {
        const updatedState = this.props.updatedState;
        const ctsOptions = getCTSOptions();
        const matterDetails = this.props.matterDetails;

        const onPeriodChange = (selectedPeriod: any, whichValue: string) => {
            const datePeriod: any = getAdjustmentPeriods(selectedPeriod, matterDetails.settlementDate);
            this.props.updateValue(selectedPeriod, whichValue);

            if (selectedPeriod === 'Daily')
                this.props.updateValue(null, 'from');
            else
                this.props.updateValue(datePeriod.fromDate, 'from');

            this.props.updateValue(datePeriod.toDate, 'to');

            this.setState({
                refresh: true
            });
        }

        return (
            <div className="modal-body">
                <div className="ms-Grid" dir="ltr">

                    {showAdditionalFields(this.props.modalName.toString()) ?
                        <>
                            <CommonField
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                modalName={this.props.modalName}
                                adjustmentDate={this.props.matterDetails.adjustmentDate}
                            />
                        </>
                        :
                        <div className="ms-Grid-row modal-row">
                            <div className="ms-Grid-col ms-sm2">Amount : </div>
                            <div className="ms-Grid-col ms-sm4">
                                <TextField
                                    type="number"
                                    data-cy="amount_input"
                                    defaultValue={updatedState['amount']}
                                    onChange={(ev, newText) => this.props.updateValue(newText, 'amount')}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm6"></div>
                        </div>
                    }

                    <div className="ms-Grid-row  modal-row">
                        <div className="ms-Grid-col ms-sm2">Billing Period : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                disabled={updatedState['method'] === 'daily-average'}
                                placeholder="Please Select"
                                data-cy="billingPeriod_select"
                                label=''
                                options={billingPeriodOptions()}
                                defaultSelectedKey={updatedState['billingPeriod']}
                                onChange={(ev, newItem) => this.props.updateValue(newItem?.key, 'billingPeriod')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm1"></div>
                        <div className="ms-Grid-col ms-sm1">From: </div>

                        <div className="ms-Grid-col ms-sm4">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="paid_to_date_input"
                                datePickerName="from"
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row  modal-row">
                        <div className="ms-Grid-col ms-sm2">Adjustment Period : </div>
                        <div className="ms-Grid-col ms-sm4">
                            <Dropdown
                                disabled={updatedState['method'] === 'daily-average'}
                                placeholder='Please Select'
                                data-cy="adjustmentPeriod_select"
                                label=''
                                options={billingPeriodOptions()}
                                defaultSelectedKey={updatedState['adjustmentPeriod']}
                                onChange={(ev, newItem) => onPeriodChange(newItem?.key, 'adjustmentPeriod')}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm1"></div>
                        <div className="ms-Grid-col ms-sm1">To: </div>

                        <div className="ms-Grid-col ms-sm4">
                            <CustomDatePickers
                                disabled={false}
                                updateValue={this.props.updateValue}
                                updatedState={this.props.updatedState}
                                datacy="paid_to_date_input"
                                datePickerName="to"
                            />
                        </div>
                    </div>

                    <Separator>
                        <b>Status</b>
                    </Separator>

                    <div className="ms-Grid-row modal-row">

                        <div className='ms-Grid-col ms-sm4' >
                            <ChoiceGroup
                                className="defaultChoiceGroup"
                                defaultSelectedKey={updatedState['status']}
                                options={[
                                    {
                                        key: 'paid',
                                        text: 'Paid',
                                    },
                                    {
                                        key: 'unpaid',
                                        text: 'Unpaid'
                                    },
                                    {
                                        key: 'adjust-as-paid',
                                        text: 'Adjust as paid'
                                    }
                                ]}
                                data-cy="status_select"
                                required={true}
                                onChange={(ev, item) => this.onStatusUpdate(item)}
                            />
                        </div>
                    </div>

                    {this.state.status === 'adjust-as-paid' ?
                        <FormControlLabel label='Create Cheque' className='mui-font-override' control={<Checkbox
                            checked={this.state.createCheque}
                            color='primary'
                            size='small'
                            onChange={this.onCreateCheque} />} />
                        : <div />}
                </div>

                <Modal
                    isOpen={this.state.showChequeModal}
                    isBlocking={false}
                    onDismiss={() => {
                        this.setState({ showChequeModal: false });
                    }}
                    className={
                        this.state.showChequeModal !== null
                            ? "animated fadeIn settlement-calculator"
                            : "animated fadeOut settlement-calculator"
                    }>
                    {this.state.showChequeModal !== null && (
                        <Modals
                            closeModal={() => {
                                this.setState({
                                    showChequeModal: false,
                                    // the form is not supposed to be cancelled, so upon cancellation the checkbox should be unchecked
                                    createCheque: false
                                });
                            }}
                            deleteData={this.onDelete}
                            updateValue={this.onUpdate}
                            updatedState={this.state.modalData}
                            saveModal={this.onSave}
                            modalID={ModalIDs.payeeDetails}
                            matterDetails={this.props.matterDetails}
                            index={-1}
                            balanceFunds={0}
                            actionstepData={new ActionstepMatter()}
                            dataInputError={""}
                            statewuseReleaseFee={""}
                        />
                    )}
                </Modal>

                <div className="ms-Grid-row modal-row">
                    <div className="ms-Grid-col ms-sm12">
                        <Separator>
                            <b>Apportionment Option</b>
                        </Separator>

                        <div className="ms-Grid-row modal-row">

                            <div className="ms-Grid-col ms-sm2">CTS Option:</div>
                            <div className="ms-Grid-col ms-sm4">
                                <Dropdown
                                    data-cy="cts_option_select"
                                    id="cts_option_select"
                                    options={ctsOptions}
                                    defaultSelectedKey={updatedState['ctsOption']}
                                    onChange={(ev, item) => this.props.updateValue(item!.key, 'ctsOption', true)}
                                />
                            </div>

                            {updatedState['ctsOption'] === apportionmentOption.sharedPercentage &&
                                <div className="ms-Grid-col ms-sm6">
                                    <div className="ms-Grid-col ms-sm4">Percentage(%):</div>
                                    <div className="ms-Grid-col ms-sm8">
                                        <TextField
                                            type="number"
                                            data-cy="percentage_input"
                                            defaultValue={updatedState['percentage']}
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'percentage')}
                                        />
                                    </div>
                                </div>
                            }

                            {updatedState['ctsOption'] === apportionmentOption.entitlement &&
                                <div className="ms-Grid-col ms-sm6">
                                    <div className="ms-Grid-col ms-sm4">Value:</div>
                                    <div className="ms-Grid-col ms-sm8">
                                        <TextField
                                            data-cy="entitlement_input"
                                            placeholder='Lot Allocation/Aggregate'
                                            defaultValue={updatedState['entitlementValue']}
                                            onChange={(ev, newText) => this.props.updateValue(newText, 'entitlementValue')}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}