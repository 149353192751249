export class MatterDetails implements IMatterDetails {
    matterRef!: number;
    matter?: string | undefined;
    property?: string | undefined;
    adjustmentDate!: Date;
    settlementDate!: Date;
    settlementPlace?: string | undefined;
    settlementType?: string | undefined;
    settlementTime?: string | undefined;
    state?: string | undefined;
    conveyType?: string | undefined;

    constructor(data?: IMatterDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.matterRef = _data["matterRef"];
            this.matter = _data["matter"];
            this.property = _data["property"];
            this.adjustmentDate = _data["adjustmentDate"] ? new Date(_data["adjustmentDate"].toString()) : <any>undefined;
            this.settlementDate = _data["settlementDate"] ? new Date(_data["settlementDate"].toString()) : <any>undefined;
            this.settlementPlace = _data["settlementPlace"];
            this.settlementType = _data["settlementType"];
            this.settlementTime = _data["settlementTime"];
            this.state = _data["state"];
            this.conveyType = _data["conveyType"];
        }
    }

    static fromJS(data: any): MatterDetails {
        data = typeof data === 'object' ? data : {};
        let result = new MatterDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["matterRef"] = this.matterRef;
        data["matter"] = this.matter;
        data["property"] = this.property;
        data["adjustmentDate"] = this.adjustmentDate ? this.adjustmentDate.toString() : <any>undefined;
        data["settlementDate"] = this.settlementDate ? this.settlementDate.toString() : <any>undefined;
        data["settlementPlace"] = this.settlementPlace;
        data["settlementType"] = this.settlementType;
        data["settlementTime"] = this.settlementTime;
        data["state"] = this.state;
        data["conveyType"] = this.conveyType;
        return data;
    }
}

export interface IMatterDetails {
    matterRef: number;
    matter?: string | undefined;
    property?: string | undefined;
    adjustmentDate: Date;
    settlementDate: Date;
    settlementPlace?: string | undefined;
    settlementType?: string | undefined;
    settlementTime?: string | undefined;
    state?: string | undefined;
    conveyType?: string | undefined;
}

export class ActionstepMatter extends MatterDetails implements IActionstepMatter {
    price!: number;
    deposit!: number;

    constructor(data?: IActionstepMatter) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.price = _data["price"];
            this.deposit = _data["deposit"];
        }
    }

    static fromJS(data: any): ActionstepMatter {
        data = typeof data === 'object' ? data : {};
        let result = new ActionstepMatter();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["price"] = this.price;
        data["deposit"] = this.deposit;
        super.toJSON(data);
        return data;
    }
}

export interface IActionstepMatter extends IMatterDetails {
    price: number;
    deposit: number;
}

export class SettlementMatterViewModel implements ISettlementMatterViewModel {
    actionstepOrg?: string | undefined;
    matterId!: number;
    version!: number;
    settlementData?: SettlementInfo | undefined;
    actionstepData?: ActionstepMatter | undefined;

    constructor(data?: ISettlementMatterViewModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.actionstepOrg = _data["actionstepOrg"];
            this.matterId = _data["matterId"];
            this.version = _data["version"];
            this.settlementData = _data["settlementData"] ? SettlementInfo.fromJS(_data["settlementData"]) : <any>undefined;
            this.actionstepData = _data["actionstepData"] ? ActionstepMatter.fromJS(_data["actionstepData"]) : <any>undefined;
        }
    }

    static fromJS(data: any): SettlementMatterViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new SettlementMatterViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["actionstepOrg"] = this.actionstepOrg;
        data["matterId"] = this.matterId;
        data["version"] = this.version;
        data["settlementData"] = this.settlementData ? this.settlementData.toJSON() : <any>undefined;
        data["actionstepData"] = this.actionstepData ? this.actionstepData.toJSON() : <any>undefined;
        return data;
    }
}

export interface ISettlementMatterViewModel {
    actionstepOrg?: string | undefined;
    matterId: number;
    version: number;
    settlementData?: SettlementInfo | undefined;
    actionstepData?: ActionstepMatter | undefined;
}

export class SettlementInfo implements ISettlementInfo {
    matterDetails?: MatterDetails | undefined;
    adjustments?: { [key: string]: any; }[] | undefined;
    fees?: { [key: string]: any; }[] | undefined;
    additionalRequirements?: { [key: string]: any; }[] | undefined;
    payees?: { [key: string]: any; }[] | undefined;
    sourceOfFunds?: { [key: string]: any; }[] | undefined;
    ourRequirements?: { [key: string]: any; }[] | undefined;
    waterUsage?: { [key: string]: any; }[] | undefined;
    additionalInfo?: { [key: string]: any; } | undefined;
    vicWaterUsage?: { [key: string]: any; } | undefined;

    constructor(data?: ISettlementInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.matterDetails = _data["matterDetails"] ? MatterDetails.fromJS(_data["matterDetails"]) : <any>undefined;
            if (Array.isArray(_data["adjustments"])) {
                this.adjustments = [] as any;
                for (let item of _data["adjustments"])
                    this.adjustments!.push(item);
            }
            if (Array.isArray(_data["fees"])) {
                this.fees = [] as any;
                for (let item of _data["fees"])
                    this.fees!.push(item);
            }
            if (Array.isArray(_data["additionalRequirements"])) {
                this.additionalRequirements = [] as any;
                for (let item of _data["additionalRequirements"])
                    this.additionalRequirements!.push(item);
            }
            if (Array.isArray(_data["payees"])) {
                this.payees = [] as any;
                for (let item of _data["payees"])
                    this.payees!.push(item);
            }
            if (Array.isArray(_data["sourceOfFunds"])) {
                this.sourceOfFunds = [] as any;
                for (let item of _data["sourceOfFunds"])
                    this.sourceOfFunds!.push(item);
            }
            if (Array.isArray(_data["ourRequirements"])) {
                this.ourRequirements = [] as any;
                for (let item of _data["ourRequirements"])
                    this.ourRequirements!.push(item);
            }
            if (Array.isArray(_data["waterUsage"])) {
                this.ourRequirements = [] as any;
                for (let item of _data["waterUsage"])
                    this.waterUsage!.push(item);
            }
            if (_data["additionalInfo"]) {
                this.additionalInfo = {} as any;
                for (let key in _data["additionalInfo"]) {
                    if (_data["additionalInfo"].hasOwnProperty(key))
                        this.additionalInfo![key] = _data["additionalInfo"][key];
                }
            }
            if (_data["vicWaterUsage"]) {
                this.vicWaterUsage = {} as any;
                for (let key in _data["vicWaterUsage"]) {
                    if (_data["vicWaterUsage"].hasOwnProperty(key))
                        this.vicWaterUsage![key] = _data["vicWaterUsage"][key];
                }
            }
        }
    }

    static fromJS(data: any): SettlementInfo {
        data = typeof data === 'object' ? data : {};
        let result = new SettlementInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["matterDetails"] = this.matterDetails ? this.matterDetails.toJSON() : <any>undefined;
        if (Array.isArray(this.adjustments)) {
            data["adjustments"] = [];
            for (let item of this.adjustments)
                data["adjustments"].push(item);
        }
        if (Array.isArray(this.fees)) {
            data["fees"] = [];
            for (let item of this.fees)
                data["fees"].push(item);
        }
        if (Array.isArray(this.additionalRequirements)) {
            data["additionalRequirements"] = [];
            for (let item of this.additionalRequirements)
                data["additionalRequirements"].push(item);
        }
        if (Array.isArray(this.payees)) {
            data["payees"] = [];
            for (let item of this.payees)
                data["payees"].push(item);
        }
        if (Array.isArray(this.sourceOfFunds)) {
            data["sourceOfFunds"] = [];
            for (let item of this.sourceOfFunds)
                data["sourceOfFunds"].push(item);
        }
        if (Array.isArray(this.ourRequirements)) {
            data["ourRequirements"] = [];
            for (let item of this.ourRequirements)
                data["ourRequirements"].push(item);
        }
        if (this.waterUsage) {
            data["waterUsage"] = {};
            for (let key in this.waterUsage) {
                if (this.waterUsage.hasOwnProperty(key))
                    data["waterUsage"][key] = this.waterUsage[key];
            }
        }
        if (this.additionalInfo) {
            data["additionalInfo"] = {};
            for (let key in this.additionalInfo) {
                if (this.additionalInfo.hasOwnProperty(key))
                    data["additionalInfo"][key] = this.additionalInfo[key];
            }
        }
        if (this.vicWaterUsage) {
            data["vicWaterUsage"] = {};
            for (let key in this.vicWaterUsage) {
                if (this.vicWaterUsage.hasOwnProperty(key))
                    data["vicWaterUsage"][key] = this.vicWaterUsage[key];
            }
        }
        return data;
    }
}

export interface ISettlementInfo {
    matterDetails?: MatterDetails | undefined;
    adjustments?: { [key: string]: any; }[] | undefined;
    fees?: { [key: string]: any; }[] | undefined;
    additionalRequirements?: { [key: string]: any; }[] | undefined;
    payees?: { [key: string]: any; }[] | undefined;
    sourceOfFunds?: { [key: string]: any; }[] | undefined;
    ourRequirements?: { [key: string]: any; }[] | undefined;
    waterUsage?: { [key: string]: any; } | undefined;
    additionalInfo?: { [key: string]: any; } | undefined;
    vicWaterUsage?: { [key: string]: any; } | undefined;
}

export class ActionstepMatterInfo implements IActionstepMatterInfo {
    orgKey?: string | undefined;
    orgName?: string | undefined;
    termsEverAccepted!: boolean;
    latestTermsAccepted!: boolean;
    matterName?: string | undefined;
    matterId!: number;
    featureFlags?: FeatureFlag[] | undefined;

    constructor(data?: IActionstepMatterInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.orgKey = _data["orgKey"];
            this.orgName = _data["orgName"];
            this.termsEverAccepted = _data["termsEverAccepted"];
            this.latestTermsAccepted = _data["latestTermsAccepted"];
            this.matterName = _data["matterName"];
            this.matterId = _data["matterId"];
            if (Array.isArray(_data["featureFlags"])) {
                this.featureFlags = [] as any;
                for (let item of _data["featureFlags"])
                    this.featureFlags!.push(item);
            }
        }
    }

    static fromJS(data: any): ActionstepMatterInfo {
        data = typeof data === 'object' ? data : {};
        let result = new ActionstepMatterInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["orgKey"] = this.orgKey;
        data["orgName"] = this.orgName;
        data["termsEverAccepted"] = this.termsEverAccepted;
        data["latestTermsAccepted"] = this.latestTermsAccepted;
        data["matterName"] = this.matterName;
        data["matterId"] = this.matterId;
        if (Array.isArray(this.featureFlags)) {
            data["featureFlags"] = [];
            for (let item of this.featureFlags)
                data["featureFlags"].push(item);
        }
        return data;
    }
}

export interface IActionstepMatterInfo {
    orgKey?: string | undefined;
    orgName?: string | undefined;
    termsEverAccepted: boolean;
    latestTermsAccepted: boolean;
    matterName?: string | undefined;
    matterId: number;
    featureFlags?: FeatureFlag[] | undefined;
}

export enum FeatureFlag {
    FirstTitle = "FirstTitle",
    OldSettlementCalcLink = "OldSettlementCalcLink",
    GlobalX = "GlobalX",
}